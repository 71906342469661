import dynamic from 'next/dynamic';
import { NextSeo } from 'next-seo';
import { Fragment, useState, useRef } from 'react';
import Image from 'next/image';
import axios from 'axios';
import { useLocationState } from '../context/location';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Input from '../components/Input';
import styles from '../styles/modules/404/404.module.scss';
import Textarea from '../components/Textarea';

const Modal = dynamic(() => import('../components/Modal'));

const Page404 = () => {
  const locationState = useLocationState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const [clientName, setClientName] = useState('');
  const [clientPhone, setClientPhone] = useState('');
  const [clientComment, setClientComment] = useState('');
  const clientNameId = useRef();
  const clientPhoneId = useRef();

  const handleFormSubmit = () => {
    clientNameId.current.setError(clientName.length <= 3);
    clientPhoneId.current.setError(clientPhone.length !== 11);

    if (clientName.length > 3 && clientPhone.length === 11) {
      setIsSubmited(true);

      axios.post(process.env.NEXT_PUBLIC_API + '/client', {
        clientName,
        clientPhone: clientPhone,
        clientComment,
        pathName: window.location.pathname,
        whatComponent: '404',
        location: locationState.name,
        platform: 'web'
      });
      ym(21340345, 'reachGoal', 'all_form_submit');
    }
  };

  return (
    <Fragment>
      <Modal
        title="Заказать звонок"
        button={!isSubmited && 'Отправить'}
        action={handleFormSubmit}
        visibility={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      >
        {isSubmited ? (
          <Fragment>
            <div style={{ marginBottom: 18 }}>
              Спасибо за вашу заявку. Мы скоро с Вами свяжемся!
            </div>
            <img
              src="/images/thumbUp.png"
              alt=""
              style={{ padding: 50, width: '100%', boxSizing: 'border-box' }}
            />
          </Fragment>
        ) : (
          <Fragment>
            <div style={{ marginBottom: 18 }}>
              Оставьте свои контактные данные и мы с Вами свяжемся в самые кратчайшие сроки, поможем
              решить все имеющиеся у Вас вопросы!
            </div>
            <Input
              title="Имя"
              inputs={[
                {
                  placeholder: 'Василий Петрович',
                  value: clientName,
                  type: 'text'
                }
              ]}
              isWrappedStyle
              ref={clientNameId}
              onChange={(v) => setClientName(v[0])}
            />
            <Input
              title="Телефон"
              inputs={[
                {
                  placeholder: locationState.phone,
                  value: clientPhone,
                  mask: 'phone',
                  type: 'tel'
                }
              ]}
              isWrappedStyle
              ref={clientPhoneId}
              onChange={(v) => setClientPhone(v[0])}
            />
            <Textarea
              title="Коммментарий"
              placeholder="Например, «Помогите подобрать квартиру, может быть есть еще какие то варианты?»"
              autoHeight
              isWrappedStyle
              onChange={(v) => setClientComment(v)}
            />
            <div style={{ textAlign: 'center', opacity: 0.8, fontSize: 14, margin: '18px 0' }}>
              Вы соглашаетесь с{' '}
              <a href="/downloads/personal.pdf" target="_blank">
                политикой конфиденциальности
              </a>{' '}
              при отправке данной формы к нам в работу
            </div>
          </Fragment>
        )}
      </Modal>
      <NextSeo
        title="404 — Страница не найдена"
        description="К сожалению, запрашиваемая Вами страница не найдена. Пожалуйста, перейдите в существующий раздел"
        openGraph={{
          url: 'https://landlord.ru',
          type: 'website',
          title: '404 — Страница не найдена',
          description:
            'К сожалению, запрашиваемая Вами страница не найдена. Пожалуйста, перейдите в существующий раздел',
          images: [
            {
              url: '/images/og-preview.png'
            }
          ],
          site_name: 'LANDLORD'
        }}
      />
      <Header />
      <main>
        <section className="block">
          <div className={['fixed', styles.content].filter(Boolean).join(' ')}>
            <h1 className={styles.contentTitle}>Страница не найдена</h1>
            <div className={styles.contentDescription}>
              Неправильно набран адрес или такой страницы на сайте больше не существует. Если Вы
              считаете, что это ошибка, пожалуйста,{' '}
              <a role="button" tabIndex={0} onClick={() => setIsModalVisible(true)}>
                оставьте заявку
              </a>{' '}
              и мы перезвоним Вам!
            </div>
            <div className={styles.contentImage}>
              <Image src="/images/wondering-man.svg" fill alt="" style={{ objectFit: 'cover' }} />
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </Fragment>
  );
};

export default Page404;
